// NAVIGATION MENUS
/* Toggles Mobile Navigation */
const animatedNavigationMenu = () => {
    const mobileMenu = document.querySelector(".mobile-search-tabs")
    const searchNavButton = document.querySelector(".mobile-tabs__dropdown-toggle")


    if(mobileMenu){
        searchNavButton.addEventListener("click", () => {
            mobileMenu.classList.toggle("active")
        })
    }
    
}

animatedNavigationMenu()


// SEARCH FILTERS AND PARAMETERS MOBILE

const filterMobile = () => {

    /* Matches the width of the Close Filters Button to the Mobile Filters Button at any screensize */
    const filterMobileButton = document.querySelector(".search-filter__button")
    const filterMobileParams = document.querySelector(".search-filter__parameters")
    const closeMobileFilterActionButtons = document.querySelectorAll(".close-filter-action")
    const closeButton = document.querySelector(".search-filter__close-button")

    const filterResize = () => {
        const filterButtonWidth = filterMobileButton.getBoundingClientRect().width;
            if(closeButton){
                closeButton.style.width = filterButtonWidth+`px`;
            }

            if(window.matchMedia('(min-width: 1259px)').matches){
                filterMobileParams.classList.remove("active")
            } 

    }
    
    if(closeButton){
        window.addEventListener("resize", function() {
            filterResize()  
        })  
    }
    
    /* Opens additional mobile search filters container */
    if(filterMobileButton){
        filterMobileButton.addEventListener("click", (e) => {
            e.preventDefault();
            filterMobileParams.classList.toggle("active")
            
        })    
    }
    
    /* Close the Mobile Filters and Desktop Filters when the Cancel Button is clicked */
    if(closeMobileFilterActionButtons){
        closeMobileFilterActionButtons.forEach(closeMobileFilter => {
            closeMobileFilter.addEventListener("click", (e) => {
                e.preventDefault();
                filterMobileParams.classList.toggle("active")
            })
        })
    }

    filterResize()
}

filterMobile()


// SEARCH FILTERS AND PARAMETERS DESKTOP
const filterDesktop = () => {

    const desktopFilterButtons = document.querySelectorAll(".search-filter__desktop-filter-buttons")
    const filterDesktopToggle = document.querySelector(".search-filter__categories-toggle-wrapper")
    const filterOptions = document.querySelectorAll(".filter-options")
    const filterCheckboxOptions = document.querySelector(".search-filter__checkbox-options")
    const moreSearchFilter = document.querySelector(".more-search-filter__wrapper")
    const closeFilter = document.querySelector(".cancel-button")
    const filterMobileParams = document.querySelector(".search-filter__parameters")
    const relevanceDesktopButton = document.querySelector(".relevance-button")
    const relevanceDesktopFilterList = document.querySelector(".relevance-list")
    const residentInfoModal = document.querySelector(".search-filter__resident-info")

    let filterToggle = []

    // DESKTOP RELEVANCE FILTER
    /* Manage Style States for Relevance Filter */
    const relevanceFilterDesktop = () => {

    if(relevanceDesktopButton && relevanceDesktopFilterList){
        relevanceDesktopButton.addEventListener("click", function(){
            relevanceDesktopButton.classList.toggle("active")
            relevanceDesktopFilterList.classList.toggle("active")
        })
    }
}

    relevanceFilterDesktop()

    // CANCEL BUTTON
    /* Close the Mobile Filters and Desktop Filters when the Cancel Button is clicked */
    if(closeFilter){
        closeFilter.addEventListener("click", function(event){
            event.preventDefault()
            desktopFilterButtons.forEach(closeToggleCancel => {
                closeToggleCancel.classList.remove("active")
            })
            filterOptions.forEach( optionCancel => {
                optionCancel.classList.remove("active")
                filterMobileParams.classList.remove("active")
            })
            filterCheckboxOptions.classList.remove("active")
            filterMobileParams.classList.remove("active")
            filterToggle = []
            
        })

    }

    // CLICK OUTSIDE FILTER TO CLOSE
    /* Clicking outside of the desktop filter will close the desktop filter container 
    and removes active class from desktop filter toggle buttons */
    
    document.addEventListener('click', function(e){
        if(window.matchMedia('(min-width: 1260px)').matches){

            const filterChildren =  document.querySelector(".search-filter__global-filter-parameters")

            if(filterChildren !== e.target && !filterChildren.contains(e.target) ){
                desktopFilterButtons.forEach(closeToggleCancel => {
                    closeToggleCancel.classList.remove("active")
                })

                filterOptions.forEach((option) => {
                    option.classList.remove("active")
                    
                })
                desktopFilterButtons.forEach(closeToggle => {
                    closeToggle.classList.remove("active")
                    filterCheckboxOptions.classList.remove("active") 
                    filterToggle = []
                })
            }
        }
    })

    /* When the Resident Info Modal gets clicked, close the desktop filter trigger
        (Subject to change depending if client wants to implement their own modal)
    */
    if(residentInfoModal){
        residentInfoModal.addEventListener('click', function(){  
                
                    desktopFilterButtons.forEach(closeToggleCancel => {
                        closeToggleCancel.classList.remove("active")
                    })

                    filterOptions.forEach((option) => {
                        option.classList.remove("active")
                        
                    })
                    desktopFilterButtons.forEach(closeToggle => {
                        closeToggle.classList.remove("active")
                        filterCheckboxOptions.classList.remove("active") 
                        filterToggle = []
                    })
            
        })
    }

   
    
    // HANDLE TOGGLES AND OPEN RIGHT ACCORDION INPUT GROUPS
    if(desktopFilterButtons){

        desktopFilterButtons.forEach(closeToggle => {
            closeToggle.classList.remove("active")
            filterCheckboxOptions.classList.remove("active") 
            filterToggle = []
        })

        desktopFilterButtons.forEach((filterButton, filterButtonIndex) => {
    
            filterButton.addEventListener("click", function(e){
    
                desktopFilterButtons.forEach(closeToggle => {
                    closeToggle.classList.remove("active")
                    if(relevanceDesktopButton && relevanceDesktopFilterList){
                        relevanceDesktopButton.classList.remove("active")
                        relevanceDesktopFilterList.classList.remove("active")
                    }
                    
                })            
    
                e.preventDefault()
                filterCheckboxOptions.classList.add("active")
    
                filterOptions.forEach((option, optionIndex, optionArray) => {

                    window.addEventListener("resize", function(){     

                        if(window.matchMedia('(max-width: 1259px)').matches){
                            desktopFilterButtons.forEach(closeToggle => {
                                closeToggle.classList.remove("active")
                                filterCheckboxOptions.classList.remove("active") 
                                filterToggle = []
                            })
                            
                        } 

                    })
    
                    if(optionIndex == filterButtonIndex){
                        option.classList.add("active")  
                        filterButton.classList.add("active")                      
                        // Allow the user to press same button to switch filter on and off
                        filterToggle.push(optionIndex)
                        const lastToggle = filterToggle[filterToggle.length-1]
                        const prevToggle = filterToggle[filterToggle.length-2]
                        
                        if(lastToggle == prevToggle){
                            filterCheckboxOptions.classList.toggle("active")
                            option.classList.toggle("active") 
                            filterButton.classList.remove("active")    
                            filterToggle = []
                        }
                    }
    
                    if(optionArray[optionIndex].classList.contains('more-search-filter__wrapper')){
                        moreSearchFilter.classList.add("active")
                    }
    
                    if(optionIndex !== filterButtonIndex){
                        option.classList.remove("active")
                    }
                })
            })
            
        })

    }

    console.log(filterDesktopToggle.getBoundingClientRect().width)
    
    // RESIZE DESKTOP DROPDOWN DYNAMICALLY
    const checkboxResize = function(){   
        if(filterDesktopToggle){
            let filterDesktopToggleWidth = filterDesktopToggle.getBoundingClientRect().width;
            
            if(window.matchMedia('(max-width: 1259px)').matches){
                filterCheckboxOptions.style.width = "100%";
            }
            
            if(window.matchMedia('(min-width: 1260px)').matches){
                if(filterDesktopToggle && filterCheckboxOptions){
                    filterCheckboxOptions.style.width = filterDesktopToggleWidth+`px`;
                }

                
            } 
        }
    }

    checkboxResize()

    window.addEventListener("resize", function() {
        checkboxResize()
    })  
    
}

filterDesktop()


// CHECKBOX SELECTOR
const checkboxSelect = () => {
    const checkboxInput = document.querySelectorAll(".checkbox-input")
    const checkboxDesktopInput = document.querySelectorAll(".desktop-checkbox-input")   

    if(checkboxInput && checkboxDesktopInput){
        for (let i = 0; i < checkboxInput.length; i++) {
            checkboxInput[i].addEventListener("change", function(){
                if(checkboxInput[i].checked == true){
                    console.log(checkboxInput[i].id + " is checked")
                }else {
                    console.log(checkboxInput[i].id + " is unchecked")
                }
            })
        }
    
        for (let j = 0; j < checkboxDesktopInput.length; j++) {
            checkboxDesktopInput[j].addEventListener("change", function(){
                if(checkboxDesktopInput[j].checked == true){
                    console.log(checkboxDesktopInput[j].id + " is checked")
                }else {
                    console.log(checkboxDesktopInput[j].id + " is unchecked")
                }
            })
            
        }

    }
}

checkboxSelect()

// SEARCH FILTER CATEGORIES ACCORDION COLLAPSE
const showFilterOptions = () => {
    const filterAccordions = document.querySelectorAll(".search-filter__containers")
    const filterAccordionsButton = document.querySelectorAll(".search-filter__categories")

    if(filterAccordions){
        filterAccordionsButton.forEach((accButton, index) => {
            accButton.addEventListener("click", function(e){
                e.preventDefault()
                filterAccordions.forEach((acc, index2) => {
                    if (index == index2) {
                        acc.classList.toggle("active")
                    }
                })
            })
        })

    }

}

showFilterOptions()


// SAVE BUTTON

const saveClick = () => {
    const saveButton = document.querySelectorAll(".results-card__save-button")

    if(saveButton){
        saveButton.forEach( save => {
        
            save.addEventListener("click", function(){
    
    
                const regularHeart = this.querySelector(".regular-heart")
                const solidHeart = this.querySelector(".solid-heart")
                const saveText = this.querySelector(".results--save")
                const savedText = this.querySelector(".results--saved")
    
                regularHeart.classList.toggle("hidden")
                solidHeart.classList.toggle("hidden")
                saveText.classList.toggle("hidden")
                savedText.classList.toggle("hidden")
    
                save.classList.toggle("saved")
    
            })
        })
    }

}

saveClick()


const listDetail = () => {
    // LIST / DETAIL
    const listButton = document.querySelector(".list-box")
    const detailButton = document.querySelector(".detail-box")

    // COURSE DETAILS
    const courseDetail = document.querySelectorAll(".list-detail-toggle")

    if(courseDetail && detailButton){
        courseDetail.forEach( desc => {
            desc.classList.add("hidden")
        
            listButton.addEventListener("click", function(){
                desc.classList.add("hidden")
                listButton.classList.toggle("selected-box")
            })
        
            detailButton.addEventListener("click", function(){
                desc.classList.remove("hidden")
                detailButton.classList.toggle("selected-box")
            })
            
        })
        
        listButton.classList.add("selected-box")
        
        listButton.addEventListener("click", function(){
            listButton.classList.add("selected-box")
            detailButton.classList.remove("selected-box")
        })
        
        detailButton.addEventListener("click", function(){
            detailButton.classList.add("selected-box")
            listButton.classList.remove("selected-box")
            
        })
    }
    
}

listDetail()


// SHORTLIST
const shortListMenu = () => {
    const savedListButton = document.querySelectorAll(".save-list-button")
    const shortlist = document.querySelector(".shortlist")
    const shortlistCloseButton = document.querySelector(".shortlist__close-button")

    if(savedListButton && shortlist){
        savedListButton.forEach(favList => {
            favList.addEventListener("click", function(){      
                shortlist.classList.toggle("hidden")
            })
        
        })
    
        shortlistCloseButton.addEventListener("click", function(){
            shortlist.classList.toggle("hidden")
        })
    }
    
}

shortListMenu()


// APPLY FILTERS TEXT CHANGE
const applyFilters = () => {
    
    const applyFilterButton = document.querySelector(".apply-filter-button")

    if(applyFilterButton){
        const applyTextChange = () => {
                if(window.matchMedia('(min-width: 768px)').matches){
                    applyFilterButton.innerText="Apply Filters"
                }
                if(window.matchMedia('(min-width: 1260px)').matches){
                    applyFilterButton.innerText="Apply"
                }
        }
    
        applyTextChange()
        
        window.addEventListener("resize", function() {
            applyTextChange();
        })
    }
}

applyFilters()


// MODAL
const modalPopup = () => {
    const residentInfo = document.querySelector(".search-filter__resident-info")
    const modal = document.querySelector(".search-modal__wrapper")
    const closeModal = document.querySelectorAll(".search-modal__dismiss-modal")
    const scrollbarWidth = document.body.offsetWidth - document.body.clientWidth;

    if(residentInfo){
        residentInfo.addEventListener("click", function(e){
            e.preventDefault()
            modal.classList.remove("inactive")
            modal.classList.add("active")
            document.body.style.overflowY="hidden"
            document.querySelector("html").style.paddingRight = scrollbarWidth+"px"
        })
    }

    if(modal && closeModal){
        
        closeModal.forEach(close => {
            close.addEventListener("click", function(el){
                el.preventDefault()
                modal.classList.remove("active")
                modal.classList.add("inactive")
                document.body.style.overflowY="scroll"
                document.querySelector("html").style.paddingRight = "0"
            })
        })
    
    }

}

modalPopup()